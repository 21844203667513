<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="dialog = true">
        <v-icon>mdi-plus-thick</v-icon> Add New Staff
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Staff"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="staffs"
          :search.sync="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isDescending"
          :footer-props="{
            showFirstLastPage: true,
          }"
        >
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="StaffForm">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.staffCode"
                            label="Staff Code"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.staffNumber"
                            label="Staff Number"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.staffName"
                            label="Staff Name"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Phone"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.costPerHour"
                            label="Cost Per Hour"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium color="error" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
export default {
  name: "DataTablesView",
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Staff Code",
        align: "start",
        value: "staffCode",
      },
      { text: "Staff Number", value: "staffNumber" },
      { text: "Staff Name", value: "staffName" },
      { text: "Phone", value: "phone" },
      { text: "Cost Per Hour", value: "costPerHour" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    sortBy: "staffId",
    isDescending: true,
    staffs: [],
    editedIndex: -1,
    editedItem: {
      staffCode: "",
      staffNumber: "",
      staffName: "",
      phone: "",
      costPerHour: "",
    },
    defaultItem: {
      staffCode: "",
      staffNumber: "",
      staffName: "",
      phone: "",
      costPerHour: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Staff" : "Edit Staff";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/Staff").then((response) => {
        this.staffs = response.data;
      });
    },

    editItem(item) {
      this.editedIndex = this.staffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.staffs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let staffIndex = this.editedIndex;
      this.$http
        .get(`/Staff/Trash/${this.editedItem.staffId}`, {
          params: {
            id: this.editedItem.staffId,
          },
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.staffs.splice(staffIndex, 1);
            this.$toast.success("Staff deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the staff.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.StaffForm.validate()) {
        let staffIndex = this.editedIndex;
        if (staffIndex > -1) {
          let item = this.editedItem;
          this.$http
            .put(`/Staff/${this.editedItem.staffId}`, this.editedItem)
            .then((response) => {
              if (response.data.isSuccess) {
                this.staffs.splice(staffIndex, 1, item);
                this.$toast.success("Staff Updated successfully.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(
                  "Something went wrong while updating the staff.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            });
        } else {
          this.$http.post("/Staff", this.editedItem).then((response) => {
            if (response.data.isSuccess) {
              this.staffs.unshift(response.data.outputObject);
              this.$toast.success("Staff added successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(
                "Something went wrong while adding staff.",
                "Error",
                {
                  position: "topRight",
                }
              );
            }
          });
        }
        this.close();
      }
    },
  },
};
</script>
